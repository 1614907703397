<template>
  <UiPopup
    :model-value="modelValue"
    title="History of edits"
    size="medium"
    :loading="loading"
    popup-classes="rounded-[32px] sm:!py-4 sm:px-3"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="flex flex-col gap-2.5 rounded-2xl border border-primary-20 p-4 text-left">
      <p class="text-subhead-4">{{ item.body }}</p>
      <hr class="border-primary-20" />
      <UiLoader v-if="loading" />
      <template v-else>
        <div v-for="(message, index) in historyMessage" :key="index" class="flex flex-col gap-2.5">
          <div>
            <div class="mb-0.5 flex items-center gap-1">
              <UiAvatar :user="user" :size="1" classes="w-5 min-w-5 h-5 text-subhead-4" />
              <p class="text-subhead-4">{{ user.name }}</p>
              <span class="text-caption-2 ml-auto text-black-60">{{
                format(new Date(message.created_at), 'HH:mm')
              }}</span>
            </div>
            <p class="text-body-2 text-black-70">{{ message.body }}</p>
          </div>
        </div>
      </template>
      <hr class="border-primary-20" />
      <span class="text-body-2 flex items-center justify-end gap-1 text-black-60">
        {{ item.isEdited ? 'Edited ' : '' }}
        <template v-if="item.isFromMe">
          <UiIcon
            v-if="item.ackStatus === 'READ' || item.ackStatus === 'DEVICE' || item.ackStatus === 'PLAYED'"
            name="double-check"
            class="!h-4 !w-4 text-black-60"
            :class="{ '!text-primary-120': item.ackStatus === 'READ' || item.ackStatus === 'PLAYED' }"
          />
          <UiIcon v-else name="check" class="!h-4 !w-4 text-black-60" />
        </template>
        {{ format(new Date(item.whatsappCreatedAt), 'HH:mm') }}
      </span>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { WhatsappMessage, User } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  item: WhatsappMessage
  user: User
}

const props = defineProps<Props>()

const loading = ref(true)
const historyMessage = ref<{ body: string; created_at: string }[]>([])

onNuxtReady(async () => {
  loading.value = true
  try {
    const { data } = await useGetWAMessageHistory(props.item.id)
    historyMessage.value = data
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
})
</script>

<style scoped></style>
